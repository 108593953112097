<template>
    <!-- 续费记录-充值 -->
    <div class="record">
        <template v-if="rechargeList.length">
            <div class="list">
                <div class="tip">{{$t('renew.only10')}}</div>
                <div class="item" v-for="(item,index) in rechargeList" :key="index">
                    <div class="caption">
                        <div class="left">
                            {{$t('common.recharge')}}
                        </div>
                        <div class="right" @click="jump(item.tx,item.trade_type)">
                            <i class="iconfont2 icon-tiaozhuan"></i>
                        </div>
                    </div>
                    <div class="bd">
                        <div class="row">
                            <div class="title">{{$t('renew.renewAddress')}}</div>
                            <div class="text">{{item.address=='light-wallet'?'Light-wallet':item.address?item.address:'Light-admin'}}</div>
                        </div>
                        <div class="row">
                            <div class="title">{{$t('renew.renewAmount')}}</div>
                            <div class="text">{{item.num}} USDT</div>
                        </div>
                        <div class="row">
                            <div class="title">{{$t('renew.renewTime')}}</div>
                            <div class="text">{{item.time}}</div>
                        </div>
                        <div class="row">
                            <div class="title">{{$t('renew.renewSource')}}</div>
                            <div class="text">{{
                                !item.address?'Light-admin':
                                item.trade_type==0?'/':
                                item.trade_type==1?'BEP20':
                                item.trade_type==2?'TRC20':
								item.trade_type==3?'Light-wallet':item.trade_type
                            }}</div>
                        </div>
                        <div class="row">
                            <div class="title">{{$t('renew.renewState')}}</div>
                            <div class="text">{{item.state?$t('state')[item.state]:item.state}}</div>
                        </div>
                        <div class="row">
                            <div class="title">{{$t('renew.endTime')}}</div>
                            <div class="text">{{item.time_pre}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <el-empty class="empty" v-else :image="emptyImg" :image-size="120" />

    </div>
</template>
<style scoped="scoped" lang="stylus">
	@import 'record.styl';
</style>
<script>
import { Loading } from 'element-ui';

import {mapGetters} from "vuex"
// api
import {getPayRecord} from '@/api/user'
export default {
    name: 'renewRecord',
    data() {
        return {
            emptyImg: require('@/assets/images/norecord.png'),
            
            rechargeList: [],
        }
    },
    computed:{
        ...mapGetters(['account','isMobile']),
    },
    created(){
        if(this.isMobile==1){
            const loading = Loading.service({
                target: '.main',
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
                customClass: 'apploading',
            });

            // 续费记录
            getPayRecord({account:this.account}).then(res=>{
                // console.log(res)
                this.rechargeList=res;

                loading.close();
            }).catch(err=>{
                loading.close();
            })
        }else{
            this.$router.push({
                path:'/recharge'
            })
        }
    },
    methods: {
        jump(path,type){
            // type: 0-无、1-bep20、2-trc20、3-light_wallet
            if(type==3){
                // 如果是闪电钱包 提示不支持查看
                this.$message({
                    message: this.$t('tip.notSupported'),
                    type: 'info',
                    center: true,
                    customClass: 'shotMsg',
                });
            }else if(!!path){
                // window.location.href=path
                window.open(path)
            }else{
                this.$message({
                    message: this.$t('tip.txSeachErr'),
                    type: 'error',
                    center: true,
                    customClass: 'shotMsg',
                });
            }
        },
    }
}
</script>